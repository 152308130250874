Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.formContentType = "multipart/form-data";
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.userStatisticAPiEndPoint = "visual_analytics/user_statistic";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";

exports.postMethod = "POST";
exports.putMethod = "PUT";
exports.validationApiMethodType = "GET";
exports.getMethod = "GET"
exports.apiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postApiMethod = "POST";
exports.deleteAPIMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LiveStreaming";
exports.labelBodyText = "LiveStreaming Body";
exports.postApiMethodType = "POST";
exports.getApiMethodType = "GET";
exports.putApiMethodType="PUT";
exports.deleteApiMethodType = "DELETE";
exports.loginInApiEndPoint = "bx_block_login/logins";
exports.loginId = "y@y.com";
exports.loginPass = "Y@123";
exports.btnExampleTitle = "CLICK ME";
exports.Email = "buraktest1@gmail.com";
exports.Password = "123456";
exports.patchMethod = "PATCH";
exports.createStage = 'bx_block_ivslivestreams/livestreams/create_stage';
exports.createChannel = "bx_block_ivslivestreams/livestreams/create_channel";
exports.createChatRoom = "bx_block_ivslivestreams/livestreams/create_chat_room";
exports.createChatToken = "bx_block_ivslivestreams/livestreams/create_chat_token";
exports.createParticipantToken = 'bx_block_ivslivestreams/livestreams/create_participant_token';
exports.stopStream = "bx_block_ivslivestreams/livestreams/stop_ivsstream";
exports.deleteChannel = "bx_block_ivslivestreams/livestreams/remove_channel";
exports.deleteStage = "bx_block_ivslivestreams/livestreams/remove_stage";
exports.deleteChatRoom = "bx_block_ivslivestreams/livestreams/remove_room";
exports.createStageInvite = "bx_block_ivslivestreams/livestreams/create_stage_invite";
exports.listivsstreams = "bx_block_ivslivestreams/livestreams/list_ivsstreams?max_results=100";
exports.listStages = "bx_block_ivslivestreams/livestreams/list_stages?is_challenge=false&per_page=100";
exports.listChallengeStage = "bx_block_ivslivestreams/livestreams/list_stages?is_challenge=true&per_page=100";
exports.createRecording = "bx_block_ivslivestreams/livestreams/create_recording_configuration";
exports.removeRecording = "bx_block_ivslivestreams/livestreams/remove_recording_configuration";
exports.updateInvite = "bx_block_ivslivestreams/livestreams/update_stage_invite";
exports.updateLiveBasicDetails = "bx_block_ivslivestreams/livestreams/update_basic_details";
exports.creategifts = "bx_block_ivslivestreams/coin_gifts";

exports.getEndedStreamDetails = "bx_block_ivslivestreams/livestreams/live_end_dashboard";
exports.getLiveStreamAccounts = "bx_block_elasticsearch/live_stream_account_search";
exports.accountSearch = "bx_block_elasticsearch/account_search";
exports.creategifts = "bx_block_ivslivestreams/coin_gifts";
exports.getProfileData = "account_block/user?id=";
exports.updateStage = "bx_block_ivslivestreams/livestreams/update_stage";
exports.durationLiveStream = "bx_block_ivslivestreams/livestreams/duration_live_stream"
exports.teamAllocation = "bx_block_ivslivestreams/livestreams/allocate_teams"

exports.followAccount = "bx_block_followers/follows";
exports.followRequest = "/bx_block_request_management/requests";
exports.getCurrentStageData = "bx_block_ivslivestreams/livestreams/get_stage_data";
exports.selfCancelRequest = "/bx_block_request_management/self_cencel_request?id=";
exports.updateChallengeType = "bx_block_ivslivestreams/livestreams/update_challenge_type";
exports.attachRecording = "bx_block_ivslivestreams/livestreams/attach_recording";
exports.getCatelogById = "/catalogue/catalogues/";
// Customizable Area End